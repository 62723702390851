import React, { useState, useEffect } from "react";
import { VscGear } from "react-icons/vsc";
import { HiArrowCircleDown } from "react-icons/hi";
import ModalSwap from "./ModalSwap";
import Button from "./elements/button";
import useCalculatorSwap from "../hooks/useCalculatorSwap";



export default function Swap() {

    const { onChangeCalculator, onChangeSwap, onSubmitBuy, modalProps, slippage, formSwap, approve, account, onSubmitApprove, balance, formCalculator } = useCalculatorSwap()

    return (

        <div className="w-full  p-5">



            <div className="block relative z-[50] mt-7 bg-pinkSemiDark p-4 rounded-2xl shadow-xl">

                <ModalSwap {...modalProps} />
                <div className=" m-auto my-3">

                    <section className="md:w-[70%] w-[100%] m-auto pt-2 flex justify-between pb-5 ">
                        <p className=" font-bold text-xl text-pinkDark " >Sell Calculator</p>
                        <div className="mr-4 cursor-pointer">
                            {/* <VscGear size={25} className-="" color="#591830" onClick={() => { modalProps.setModal(true) }} /> */}

                        </div>
                    </section>


                    <section className="bg-[#fff] p-2 flex justify-between items-center  rounded-lg w-[70%] m-auto mb-5">
                        <input value={formCalculator.inputPeach} onChange={onChangeCalculator} type="number" className=" bg-transparent text-[#000] outline-none placeholder-[#591830]" placeholder="YOU SELL..." />
                        <p className="mr-4 font-bold text-[#591830]" >USD</p>
                    </section>


                    <section className="bg-[#fff] p-2 flex justify-between items-center  rounded-lg w-[70%] m-auto ">
                        <input value={formCalculator.inputBnb} type="number" className=" bg-transparent text-[#000] outline-none placeholder-[#591830]" placeholder="YOU RECEIVE..." />
                        <p className="mr-4 font-bold text-[#591830]" >USD</p>
                    </section>

                    <section className="md:w-[70%] w-[100%] m-auto pt-2 flex justify-between pb-5 mt-5 ">
                        <p className=" font-bold text-xl text-pinkDark " >Exchange</p>
                        <div className="mr-4 cursor-pointer">
                            {/* <VscGear size={25} className-="" color="#591830" onClick={() => { modalProps.setModal(true) }} /> */}

                            <p className=" font-bold text-xl text-pinkDark " >  {isNaN(balance) ? 0 : balance} PEACH</p>
                        </div>
                    </section>


                    <section className="bg-[#fff] p-2 flex justify-between items-center  rounded-lg w-[70%] m-auto mb-5">
                        <input value={formSwap.inputPeach} onChange={onChangeSwap} type="number" className=" bg-transparent text-[#000] outline-none placeholder-[#591830]" placeholder="FROM..." />
                        <p className="mr-4 font-bold text-[#591830]" >PEACH</p>
                    </section>


                    <section className="bg-[#fff] p-2 flex justify-between items-center  rounded-lg w-[70%] m-auto ">
                        <input value={formSwap.inputBnb} type="number" className=" bg-transparent text-[#000] outline-none placeholder-[#591830]" placeholder="TO..." />
                        <p className="mr-4 font-bold text-[#591830]" >BNB</p>
                    </section>

                    {/*  <section className="flex justify-between mt-4  w-[70%] m-auto">
                        <p className="text-md text-[#591830] font-semibold ">Slippage Tolerance</p>
                        <p className="text-md text-[#591830]">{slippage}%</p>

                    </section> */}
                    <section className="flex justify-center mt-7">
                        {approve
                            ? <Button onSubmitApprove={onSubmitBuy} >Swap </Button>
                            : <Button onSubmitApprove={onSubmitApprove} >Approve</Button>
                        }
                    </section>
                </div>
            </div >
        </div>
    )
}
